<template>
  <v-form ref="form" v-model="isFormValid" v-on:submit.prevent="safeData()">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="permissions"
            :items="permissionItems"
            item-text="name"
            item-value="value"
            attach
            chips
            :label="$t('permissions')"
            multiple
            :hint="$t('employeePermissionInfo')"
            persistent-hint
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-select
            v-model="sex"
            :items="genderItems"
            item-text="name"
            item-value="value"
            :label="$t('gender')"
            :rules="[rules.required]"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('firstName')"
            v-model="firstname"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('lastName')"
            v-model="lastname"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field :label="$t('phone')" v-model="phone"></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            :label="$t('email')"
            v-model="mail"
            :rules="emailRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <div class="d-flex align-end flex-column">
            <v-row>
              <v-btn color="grey" text @click="$emit('closeDialog')">{{
                $t("cancel")
              }}</v-btn>
              <v-btn
                v-if="isFormValid"
                color="green"
                dark
                @click="safeData"
                :loading="fetchinData"
                >{{ $t("safe") }}</v-btn
              >
              <v-btn v-else disabled>{{ $t("safe") }}</v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <ConfirmationDialog
      :dialog="mailErrorDialog"
      :headline="$t('error')"
      :message="$t('emailIsAlreadyInUse')"
      @reject="mailErrorDialog = false"
      @accept="mailErrorDialog = false"
    />
  </v-form>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import ConfirmationDialog from "@/components/ui/ConfirmationDialog";
export default {
  name: "EmployeeNew",
  components: {
    ConfirmationDialog,
  },

  data() {
    return {
      mailErrorDialog: false,
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      componentKey: 0,
      firstname: null,
      lastname: null,
      sex: null,
      phone: null,
      mail: null,
      permissions: null,
      language: "de",
      isFormValid: false,
      genderItems: [
        {
          name: this.$t("female"),
          value: "female",
        },
        {
          name: this.$t("male"),
          value: "male",
        },
        {
          name: this.$t("diverse"),
          value: "diverse",
        },
      ],

      permissionItems: [
        {
          name: this.$t("jmp_fitness"),
          value: "2",
        },
        {
          name: this.$t("leads"),
          value: "4",
        },
        {
          name: this.$t("campaigns"),
          value: "5",
        },
        {
          name: this.$t("shop"),
          value: "10",
        },
        {
          name: this.$t("contracts"),
          value: "12",
        },
        {
          name: this.$t("company"),
          value: "6",
        },
        {
          name: this.$t("gdpr"),
          value: "7",
        },
        {
          name: this.$t("settings"),
          value: "9",
        },
      ],
      rules: {
        required: (value) => !!value || this.$t("required"),
      },
      emailRules: [
        (v) => !!v || this.$t("required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("mailMustBeValid"),
      ],
    };
  },

  props: ["itemData"],

  methods: {
    async safeData() {
      this.fetchinData = true;

      let mailExists = await this.getRequest("admins/check/" + this.mail); // await data from mixin

      if (!mailExists.data.data) {
        let permissionsString = "";

        this.permissions.forEach((item) => {
          if (permissionsString === "") {
            permissionsString += item;
          } else {
            permissionsString += "," + item;
          }
        });

        var formData = new FormData();
        formData.append("type", "employee");
        formData.append("companies_id", this.companyID);
        formData.append("sex", this.sex);
        formData.append("firstname", this.firstname);
        formData.append("lastname", this.lastname);
        formData.append("phone", this.phone);
        formData.append("mail", this.mail);
        formData.append("permissions", this.permissions);
        formData.append("language", this.language);

        this.fetchinData = true;
        let response = await this.postRequest("admins", "", formData); // await data from mixin
        this.fetchinData = false;

        if (response.status === 200) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
          this.$emit("newItemAdded");
        }
      } else {
        /* Mail already exists */
        this.mailErrorDialog = true;
        this.fetchinData = false;
      }
    },
  },

  /* all requests by mixin */
  mixins: [manageApi],
};
</script>
